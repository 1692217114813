import React, { useEffect, useState } from "react";
import {
  MdOutlineDashboard,
  MdOutlineLibraryAddCheck,
  MdListAlt,
} from "react-icons/md";
import { RiOrganizationChart } from "react-icons/ri";
import { BsChevronDown, BsFileEarmarkPlus } from "react-icons/bs";
import { AiOutlineBlock } from "react-icons/ai";
import { Collapse, Dropdown, Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import Scrollbar from "smooth-scrollbar";
import dashboardWhite from "../../icons/DashboardWhite.svg";
import dashboardBlue from "../../icons/DashboardBlue.svg";
import reportWhite from "../../icons/ReportWhite.svg";
import reportBlue from "../../icons/ReportBlue.svg";
import createSolnBlue from "../../icons/CreateSolnBlue.svg";
import createSolnWhite from "../../icons/CreateSolnWhite.svg";
import masterBlue from "../../icons/MasterBlue.svg";
import masterWhite from "../../icons/MasterWhite.svg";
import allSolnRecordsBlue from "../../icons/AllSolnRecordsBlue.svg";
import allSolnRecordsWhite from "../../icons/AllSolnRecordsWhite.svg";
import solnPendingBlue from "../../icons/SolnPendingBlue.svg";
import solnPendingWhite from "../../icons/SolnPendingWhite.svg";
import {
  APPROVAL_HIEARCHY_MASTER,
  COMPRESSOR_MASTER,
  COUNTRY_MASTER,
  CUSTOMER_MASTER,
  DEALER_MASTER,
  LOCATION_MASTER,
  MAIL_REMINDER,
  PRODUCT_MASTER,
  ROLE_AUTHORIZATION_MASTER,
  UNIT_CONVERSION_MASTER,
  USER_MASTER,
  CREATE_NEW_SOLUTION,
  DASHBOARD,
  REPORTS,
  ALL_SOLUTION_RECORDS,
  PENDING_FOR_APPROVAL,
} from "../../utils/constant";
import AEM_Logo from "../../icons/GCEM_Logo.svg";
import Toggler from "../../icons/Toggler.svg";
import HeadLogo from "../../icons/HeadLogo.svg";
import { store } from "../../redux/store";
import { useDispatch } from "react-redux";
import { getInitials } from "../../functions/functions";
import { useLocation, useHistory } from "react-router-dom";
import { setLogout, setSideBar } from "../../redux/redux/global/action";
import DownArrow from "../../icons/DownArrow.svg";
import LogOut from "../../icons/LogOut.svg";
import LogOutWhite from "../../icons/Logoutwhite.svg";
import { useMsal } from "@azure/msal-react";
import ConfirmAlertModal from "../Modals/confirmAlertModal";
import godrejLogo from "../../styles/images/godrej-logo-dark.svg"

const SideBarComp = ({
  menuName,
  userName,
  userEmail,
  setMenuName,
  showSidebar,
  setShowSidebar,
}) => {
  // const [showSidebar, setShowSidebar] = useState(false);
  const [menu, setMenu] = useState(false);
  const [show, setShow] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  let access = store.getState().global?.userDetails?.accessData || [];
  const location = useLocation();
  console.log("location", location);
  console.log("menu", menu);
  let initials = getInitials(userName);
  const dispatch = useDispatch();
  const history = useHistory();
  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
    setMenu(false);
    let sidebar1 = !showSidebar;
    dispatch(setSideBar(sidebar1));
  };
  const funcReload = () => {
    let query = window.location.href;
    let indication = query.split("/").pop();
    //alert("Reloading...");
    if (indication == "newenquiry") {
      window.location.reload();
    }
  };
  const CustomToggle = React.forwardRef(({ onClick }, ref) => (
    <img
      src={DownArrow}
      alt="arrow"
      className="user-action"
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    />
  ));
  const { instance } = useMsal();
  function handleLogout(instance) {
    instance.logoutRedirect().catch((e) => {
      console.error(e);
    });
  }

  function handleLogout1() {
    if (localStorage.getItem("Type") === "Non Employee") {
      localStorage.setItem("Type", "");
      dispatch(setLogout());
      history.push("/");
    } else {
      localStorage.setItem("Type", "");
      dispatch(setLogout());
      history.push("/");
      handleLogout(instance);
    }
  }

  useEffect(() => {
    const Scroll = Scrollbar.init(document.querySelector("#Sidebar-Body"));
    Scroll.track.xAxis.element.remove();
  }, []);

  return (
    <>
      <ConfirmAlertModal
        handleYes={handleLogout1}
        formName={"Logout"}
        show={show}
        handleClose={() => setShow(false)}
      />
      <div
        id="Sidebar"
        className={showSidebar ? "side-bar open" : "side-bar closed"}
      >
        <div className="sidebar-head">
          <img src={AEM_Logo} alt="AEM Logo" className="head-logo ms-2" />
          {showSidebar && (
            <img src={Toggler} alt="Toggler" onClick={toggleSidebar} />
          )}
          {!showSidebar && (
            <>
              <img
                src={Toggler}
                alt="Toggler"
                className="d-md-none d-lg-none"
                onClick={toggleSidebar}
              />
              <img
                src={HeadLogo}
                alt="HeadLogo"
                className="head-toggle d-none d-md-block d-lg-block"
                onClick={toggleSidebar}
              />
            </>
          )}
        </div>
        {showSidebar && (
          <div className="sidebar-head-2 d-md-none d-lg-none">
            <div className="user-section">
              <div className="user-logo">
                <span>{initials}</span>
              </div>
              <div className="user-info">
                <span className="user-name">{userName}</span>
                <span className="user-email">{userEmail}</span>
              </div>
            </div>
            <div
              bsPrefix="headerMenu-item"
              eventKey="1"
              onClick={() => {
                if (localStorage.getItem("Type") === "Non Employee") {
                  localStorage.setItem("Type", "");
                  dispatch(setLogout());
                  history.push("/");
                } else {
                  localStorage.setItem("Type", "");
                  dispatch(setLogout());
                  history.push("/");
                  handleLogout(instance);
                }
              }}
            >
              <img src={LogOut} alt="LogOut" />
            </div>
          </div>
        )}
        {/* <div className="sidebar-body d-none d-md-block d-lg-block" id="Sidebar-Body"> */}
        <div className="sidebar-body" id="Sidebar-Body">
          <span className="menu">Menu</span>
          <Nav
            as="ul"
            bsPrefix="nav-list"
            defaultActiveKey={"Dashboard"}
            onSelect={(selectedKey) => setMenuName(selectedKey)}
          >
            {access.includes(DASHBOARD) && (
              <Nav.Item as="li" className="list1">
                <Nav.Link
                  as={Link}
                  to="/dashboard"
                  eventKey={"Dashboard"}
                  bsPrefix="nav-list-item"
                  className={
                    location.pathname === "/dashboard" ? "active1" : "inactive"
                  }
                >
                  {/* <MdOutlineDashboard size={32} className="menu-icon" /> */}
                  <div style={{ position: "relative" }}>
                    <img
                      src={dashboardWhite}
                      alt="dashboard"
                      className="img1"
                    />
                    <img src={dashboardBlue} alt="dashboard" className="img2" />
                  </div>
                  <span className="menu-name">Dashboard</span>
                </Nav.Link>
              </Nav.Item>
            )}
            {access.includes(REPORTS) && (
              <Nav.Item as="li" className="list2">
                <Nav.Link
                  as={Link}
                  to="/report"
                  eventKey={"Reports"}
                  bsPrefix="nav-list-item"
                  className={
                    location.pathname === "/report" ? "active1" : "inactive"
                  }
                >
                  {/* <MdListAlt size={32} className="menu-icon" /> */}
                  <div style={{ position: "relative" }}>
                    <img src={reportWhite} alt="report" className="img1" />
                    <img src={reportBlue} alt="report" className="img2" />
                  </div>
                  <span className="menu-name">Reports</span>
                </Nav.Link>
              </Nav.Item>
            )}
            {access.includes(CREATE_NEW_SOLUTION) && (
              <Nav.Item as="li" className="list3">
                <Nav.Link
                  as={Link}
                  to="/newenquiry"
                  eventKey={"Create New Solution"}
                  onClick={() => {
                    funcReload();
                  }}
                  bsPrefix="nav-list-item"
                  className={
                    location.pathname === "/newenquiry" ? "active1" : "inactive"
                  }
                >
                  {/* <BsFileEarmarkPlus size={32} className="menu-icon" /> */}
                  <div style={{ position: "relative" }}>
                    

<img
                      src={createSolnWhite}
                      alt="allSolnRecords"
                      className="img1"
                    />
                    <img
                      src={createSolnBlue}
                      alt="allSolnRecords"
                      className="img2"
                    />
                  </div>
                  <span className="menu-name">Create New Solution</span>
                </Nav.Link>
              </Nav.Item>
            )}
            {access.includes(ALL_SOLUTION_RECORDS) && (
              <Nav.Item as="li" className="list4">
                <Nav.Link
                  as={Link}
                  to="/allsolution"
                  eventKey={"All Records"}
                  bsPrefix="nav-list-item"
                  className={
                    location.pathname === "/allsolution"
                      ? "active1"
                      : "inactive"
                  }
                >
                  {/* <AiOutlineBlock size={32} className="menu-icon" /> */}
                  <div style={{ position: "relative" }}>
                  <img
                      src={allSolnRecordsWhite}
                      alt="createSoln"
                      className="img1"
                    />
                    <img
                      src={allSolnRecordsBlue}
                      alt="createSoln"
                      className="img2"
                    />
                  </div>
                  <span className="menu-name">All Solution Records</span>
                </Nav.Link>
              </Nav.Item>
            )}
            {access.includes(PENDING_FOR_APPROVAL) && (
              <Nav.Item as="li" className="list5">
                <Nav.Link
                  as={Link}
                  to="/allsolutionapproval"
                  eventKey={"All Soultions for Approval"}
                  bsPrefix="nav-list-item"
                  className={
                    location.pathname === "/allsolutionapproval"
                      ? "active1"
                      : "inactive"
                  }
                >
                  {/* <MdOutlineLibraryAddCheck size={32} className="menu-icon" /> */}
                  <div style={{ position: "relative" }}>
                    <img
                      src={solnPendingWhite}
                      alt="solnPending"
                      className="img1"
                    />
                    <img
                      src={solnPendingBlue}
                      alt="solnPending"
                      className="img2"
                    />
                  </div>
                  <span className="menu-name">
                    Solutions Pending for Approval
                  </span>
                </Nav.Link>
              </Nav.Item>
            )}

            <hr style={{ margin: "8px" }} />
            {(access.includes(USER_MASTER) ||
              access.includes(COMPRESSOR_MASTER) ||
              access.includes(DEALER_MASTER) ||
              access.includes(PRODUCT_MASTER) ||
              access.includes(ROLE_AUTHORIZATION_MASTER) ||
              access.includes(UNIT_CONVERSION_MASTER) ||
              access.includes(USER_MASTER) ||
              access.includes(LOCATION_MASTER) ||
              access.includes(CUSTOMER_MASTER) ||
              access.includes(COUNTRY_MASTER) ||
              access.includes(MAIL_REMINDER)) && (
              <Nav.Item
                as="li"
                className="list6"
                onClick={() => {
                  setShowSidebar(true);
                  setMenu(!menu);
                }}
              >
                <Nav.Link eventKey={"Master Data"} bsPrefix="nav-list-item">
                  {/* <RiOrganizationChart size={32} className="menu-icon" /> */}
                  <div style={{ position: "relative" }}>
                    <img src={masterWhite} alt="master" className="img1" />
                    <img src={masterBlue} alt="master" className="img2" />
                  </div>
                  <span className="menu-name">
                    Master Data <BsChevronDown size={15} />
                  </span>
                </Nav.Link>
              </Nav.Item>
            )}
            <Collapse in={menu}>
              <div>
                <ul className="nav-sub-menu">
                  {access.includes(USER_MASTER) && (
                    <Nav.Item as="li">
                      <Nav.Link
                        eventKey={"User Master"}
                        as={Link}
                        to="/user"
                        bsPrefix="sub-menu-item"
                      >
                        User Master
                      </Nav.Link>
                    </Nav.Item>
                  )}

                  {access.includes(ROLE_AUTHORIZATION_MASTER) && (
                    <Nav.Item as="li">
                      <Nav.Link
                        eventKey={"Role & Authorization Master"}
                        as={Link}
                        to="/role"
                        bsPrefix="sub-menu-item"
                      >
                        Role & Authorization Master
                      </Nav.Link>
                    </Nav.Item>
                  )}
                  {access.includes(DEALER_MASTER) && (
                    <Nav.Item as="li">
                      <Nav.Link
                        eventKey={"Dealer Master"}
                        as={Link}
                        to="/dealer"
                        bsPrefix="sub-menu-item"
                      >
                        Dealer Master
                      </Nav.Link>
                    </Nav.Item>
                  )}
                  {access.includes(LOCATION_MASTER) && (
                    <Nav.Item as="li">
                      <Nav.Link
                        eventKey={"Location Master"}
                        as={Link}
                        to="/loc"
                        bsPrefix="sub-menu-item"
                      >
                        Location Master
                      </Nav.Link>
                    </Nav.Item>
                  )}
                  {access.includes(CUSTOMER_MASTER) && (
                    <Nav.Item as="li">
                      <Nav.Link
                        eventKey={"Customer Master"}
                        as={Link}
                        to="/cust"
                        bsPrefix="sub-menu-item"
                      >
                        Customer Master
                      </Nav.Link>
                    </Nav.Item>
                  )}
                  {access.includes(PRODUCT_MASTER) && (
                    <Nav.Item as="li">
                      <Nav.Link
                        eventKey={"Product Master"}
                        as={Link}
                        to="/product"
                        bsPrefix="sub-menu-item"
                      >
                        Product Master
                      </Nav.Link>
                    </Nav.Item>
                  )}
                  {access.includes(UNIT_CONVERSION_MASTER) && (
                    <Nav.Item as="li">
                      <Nav.Link
                        eventKey={"Unit Conversion Master"}
                        as={Link}
                        to="/unit"
                        bsPrefix="sub-menu-item"
                      >
                        Unit Conversion Master
                      </Nav.Link>
                    </Nav.Item>
                  )}
                  {access.includes(COMPRESSOR_MASTER) && (
                    <Nav.Item as="li">
                      <Nav.Link
                        eventKey={"Compressor Master"}
                        as={Link}
                        to="/compressor"
                        bsPrefix="sub-menu-item"
                      >
                        Compressor Master
                      </Nav.Link>
                    </Nav.Item>
                  )}
                  {access.includes(COUNTRY_MASTER) && (
                    <Nav.Item as="li">
                      <Nav.Link
                        eventKey={"Currency Master"}
                        as={Link}
                        to="/currency"
                        bsPrefix="sub-menu-item"
                      >
                        Currency Master
                      </Nav.Link>
                    </Nav.Item>
                  )}
                  {access.includes(MAIL_REMINDER) && (
                    <Nav.Item as="li">
                      <Nav.Link
                        eventKey={"Mail Reminder"}
                        as={Link}
                        to="/mail"
                        bsPrefix="sub-menu-item"
                      >
                        Mail Reminder
                      </Nav.Link>
                    </Nav.Item>
                  )}
                </ul>
              </div>
            </Collapse>
            <Nav.Item
              as="li"
              className="list7"
              onClick={() => setShow(true)}
              onMouseEnter={() => setIsHovered(!isHovered)}
              onMouseLeave={() => setIsHovered(!isHovered)}
            >
              <Nav.Link bsPrefix="nav-list-item">
                <div style={{ position: "relative" }}>
                  <img src={LogOutWhite} alt="LogOut" className="img1" />
                  <img
                    src={LogOut}
                    alt="LogOut"
                    className={isHovered ? "img2 d-none" : "img2"}
                  />
                </div>
                <span className="menu-name">Logout</span>
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </div>
      </div>
    </>
  );
};

export default SideBarComp;