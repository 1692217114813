import React, { useEffect, useState } from "react";
import { Row, Col, Form, Button, Alert } from "react-bootstrap";
// import godrejLogo from "../../styles/images/Godrej-colour-logo.svg";
// import godrejLeftImage from "../../styles/images/login-left-image.png";
import godrejLogo from "../../styles/images/godrej-logo-dark.svg"
import godrejLeftImage from "../../styles/images/loginImg.jpg"
import eyeIcon from "../../styles/images/eye-icon.svg";
import eyeIconBlue from "../../styles/images/eye-icon-blue.svg";
import AEMLogo from "../../styles/images/AEM-LogoLogin.svg";
import axios from "../../utils/axios";
import jwt_decode from "jwt-decode";
import { useHistory, withRouter } from "react-router-dom";
import { LOGIN_GODREJ_EMPLOYEE, LOGIN_DEALER } from "../../utils/constant";
import {
  setAccessToken,
  setUserDetails,
  setUserName,
} from "../../redux/redux/global/action";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useIsAuthenticated,
  useMsal,
} from "@azure/msal-react";
import { loginRequest } from "../../authConfig";
import { useDispatch } from "react-redux";
import encryptData from "../../utils/encryption";
import { compose } from "redux";
import { passwordValidator } from "../../utils/validator";

const LoginPage = (props) => {
  const [passwordToggle, setPasswordToggle] = useState(false);
  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [toggleOtp, setToggleOtp] = useState(false);
  const [formValue, setFormValue] = useState({
    loginType: LOGIN_GODREJ_EMPLOYEE,
    userName: "",
    password: "",
  });
  let err = props.history.location.state?.errorMessage;
  const [errorMessage, setErrorMessage] = useState(err ? err : "");
  let history = useHistory();
  const dispatch = useDispatch();
  const togglePassword = () => {
    setPasswordToggle(!passwordToggle);
  };
  const validateForm = () => {
    let isError = false;
    Object.values({ ...formValue }).forEach((value) => {
      if (value === "") {
        isError = true;
      }
    });
    return isError;
  };
  const { instance, accounts } = useMsal();
  const loginFunction = async () => {
    // debugger;
    setErrorMessage("");
    if (formValue.loginType === LOGIN_GODREJ_EMPLOYEE) {
      // instance.loginRedirect(loginRequest).catch(e => {
      //   console.error(e);
      // });
      history.push({
        pathname: "/SSOAuth",
      });
    } else {
      let isError = await validateForm();
      if (!isError) {
        setErrorMessage("");
        let value = {
          User: formValue.userName,
          Password: formValue.password,
        };
        value = encryptData(JSON.stringify(value), "enc");
        try {
          setLoading(true);
          let response = await axios.post(
            "/authentication/login/",
            { body: value },
            {
              headers: {},
            }
          );
          //.then((response) => {
          if (response.status === 200) {
            localStorage.setItem("Type", "Non Employee");
            //props.setValidDealer(true);
            if (
              response?.data?.isNewUser ||
              response?.data?.passwordChangeRequired
            ) {
              history.push({
                pathname: "/forgotPassword",
                data: formValue.userName,
              });
            } else {
              let token = response?.data?.data;
              let accessData =
                response?.data?.accessibleMenu
                  .split(",")
                  .map((value) => value.trim())
                  .join(",") || "";
              let JWT = jwt_decode(token);
              if (JWT) {
                JWT = {
                  ...JWT,
                  accessData: [...accessData.split(",")],
                };
                console.log("JWT VIEW", JWT);
                //store.getState().global?.userDetails?.accessData = accessData;

                dispatch(setUserDetails(JWT));
                //dispatch()
                dispatch(setAccessToken(token));
                dispatch(setUserName(JWT.um_name));
                props.setIsAuthenticated(true);
              }

              history.push({
                pathname: "/dashboard",
              });
            }
          }
          //})
        } catch (e) {
          if (e?.response?.data?.message) {
            console.log(e?.response?.data?.message);
            setErrorMessage(e?.response?.data?.message);
          }
          // setOtpError(e?.response?.data?.result?.message || "Invalid Keyword");
        } finally {
          setLoading(false);
        }
      } else {
        setErrorMessage("Check Username or Password.");
      }
    }
  };
  const sendOtp = (value, type) => {
    // debugger;
    setErrorMessage("");
    let body = {
      user: value,
    };
    axios
      .post("/authentication/forgotPassword", body, {
        headers: {},
      })
      .then((response) => {
        if (response.status === 200) {
          if (type === "forgot") {
            history.push({
              pathname: "/forgotPassword",
              data: formValue.userName,
            });
          } else {
            let temp = formValue;
            temp.password = "";
            setFormValue(temp);
            setAlertMessage("OTP Sent Successfully");
          }
        }
      })
      .catch((e) => {
        if (e?.response?.data?.message) {
          let temp = [...errorMessage];
          temp.push(e?.response?.data?.message);
          //setErrorMessage([...temp]);
          setErrorMessage(e?.response?.data?.message);
        }
      })
      .finally(() => {
        // setLoading(false);
      });
  };
  const onChange = (e) => {
    let newValues = { ...formValue, [e.target.name]: e.target.value };
    setFormValue({ ...newValues });
  };
  const onChangeLoginType = (e) => {
    let newValues = { ...formValue, [e.target.name]: e.target.value };
    setFormValue({ ...newValues, userName: "", password: "" });
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setAlertMessage("");
    }, 3000);
    return () => clearTimeout(timer);
  }, [alertMessage]);
  useEffect(() => {
    if (props?.location?.data) {
      if (props?.location?.data === "setDealer") {
        let temp = formValue;
        temp.loginType = LOGIN_GODREJ_EMPLOYEE;
        setFormValue(temp);
      }
    }
  }, []);
  return (
    <>
    <div className="loginLeftImageGodrej d-flex justify-content-between align-items-center my-3">
              <img
                src={godrejLogo}
                alt="godrejLogo"
              />
              <span class="headerLink">Godrej Enterprises Group</span>
            </div>
    <div className="login-container container-fluid text-center vh-100 overflow-hidden">
      {alertMessage && (
        <Alert className="alertBoxLogin" variant="success">
          {alertMessage}
        </Alert>
      )}

      <Row className="place-right  align-items-start d-flex">
        <Col xs={12} md={8} className="d-none d-md-block d-lg-block loginImage">
          {/* <div className="loginLeftImage"> */}
            <img
              src={godrejLeftImage}
              alt="godrejLeftImage"
              // className="LoginRightImageGodrej mt-5"
            />
          {/* </div> */}
        </Col>
        <Col
          xs={12}
          md={4}
          //   className="d-flex align-items-center justify-content-center"
        >
          <div className="LoginRightImage text-center d-flex flex-column">
            
            <div className="LoginRightImageAem text-start mb-4">
              {/* <img
                src={AEMLogo}
                alt="AEMLogo"
                className="LoginRightImageAem mt-5"
              /> */}
              <h1>GCEM</h1>
              <h5>Energy Saving Simulator</h5>
            </div>

            <div className="mt-3">
              {errorMessage && (
                <div className="loginErrorDiv">
                  <div className="loginErrorDivHeader">Login Failed</div>
                  <div className="loginErrorDivBody">{errorMessage}</div>
                </div>
              )}
              <div className="LoginRightRadioDiv flex-column d-flex ">
                
                <div className="LoginRightRadioDivTitle text-start ">User Type</div>
                <div className="input-group">
                <div className="LoginRightRadioLabel">
                  <Form.Check
                    label={LOGIN_GODREJ_EMPLOYEE}
                    name="loginType"
                    type="radio"
                    onChange={(e) => {
                      setErrorMessage("");
                      onChangeLoginType(e);
                    }}
                    value={LOGIN_GODREJ_EMPLOYEE}
                    checked={formValue.loginType === LOGIN_GODREJ_EMPLOYEE}
                    className="login-form-check"
                  />
                </div>
                <div className="LoginRightRadioLabel">
                  <Form.Check
                    label={LOGIN_DEALER}
                    name="loginType"
                    type="radio"
                    value={LOGIN_DEALER}
                    checked={formValue.loginType === LOGIN_DEALER}
                    className="login-form-check"
                    onChange={(e) => {
                      setErrorMessage("");
                      onChangeLoginType(e);
                    }}
                  />
                </div>
                </div>
              </div>
            </div>
            <>
              <div className="LoginRightInput mt-3">
                <Form.Label htmlFor="inputPassword5">
                  {formValue.loginType === LOGIN_DEALER
                    ? "User ID / Email ID / Mobile No."
                    : "User ID"}
                </Form.Label>
                <Form.Control
                  readOnly={formValue.loginType === LOGIN_DEALER ? false : true}
                  type="text"
                  name="userName"
                  className="placeHolderInput inputText login-form-control"
                  placeholder={
                    formValue.loginType === LOGIN_DEALER
                      ? "eg. xyz / xyz@gmail.com /+91 8888888888"
                      : "eg. xyz"
                  }
                  value={formValue.userName}
                  onChange={(e) => {
                    onChange(e);
                  }}
                  id="inputPassword5"
                  aria-describedby="passwordHelpBlock"
                />
              </div>
              <div className="LoginRightInput mt-3">
                <Form.Label htmlFor="inputPassword5">
                  {toggleOtp ? "OTP" : "Password"}
                </Form.Label>
                <div className="inputPassword">
                  <Form.Control
                    autoComplete="off"
                    readOnly={
                      formValue.loginType === LOGIN_DEALER ? false : true
                    }
                    type={
                      toggleOtp ? "text" : passwordToggle ? "text" : "password"
                    }
                    className="placeHolderInput inputText login-form-control"
                    name="password"
                    value={formValue.password}
                    maxLength={toggleOtp ? 6 : 50}
                    onKeyPress={(e) => e.key === "Enter" && loginFunction()}
                    onChange={(e) => {
                      if (toggleOtp && !isNaN(e.target.value)) {
                        onChange(e);
                      } else if (!toggleOtp) {
                        onChange(e);
                      }
                    }}
                    id="inputPassword5"
                  />
                  {toggleOtp ? (
                    ""
                  ) : (
                    <div className="inputPasswordEyeIcon">
                      <img
                        onClick={() => togglePassword()}
                        src={passwordToggle ? eyeIcon : eyeIconBlue}
                        alt="eyeIcon"
                      />
                    </div>
                  )}
                </div>
              </div>
            </>
            <div className="place-end">
              {toggleOtp ? (
                <div className="LoginRightRadioDiv forgotText mb-4">
                  <div
                    onClick={() => {
                      if (formValue.userName) {
                        //alert(toggleOtp)
                        // if (toggleOtp === false) {
                        setErrorMessage("");
                        sendOtp(formValue.userName);
                        //}
                        // setToggleOtp(!toggleOtp);
                      } else {
                        setErrorMessage(
                          "Please Enter User ID / Email ID / Mobile No."
                        );
                      }
                    }}
                    className="LoginRightRadioDivTitleBlue"
                  >
                    Resend OTP?
                    <div></div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="LoginRightInput mt-4">
              <Button
                onClick={() => {
                  loginFunction();
                }}
                disabled={
                  (!formValue.userName || !formValue.password) &&
                  formValue.loginType === LOGIN_DEALER
                }
                className={`${
                  (!formValue.userName || !formValue.password) &&
                  formValue.loginType === LOGIN_DEALER
                    ? "forgotButton disabledButton"
                    : "forgotButton"
                } w-100 rounded-pill`}
              >
                Login
              </Button>
            </div>
            {formValue.loginType === LOGIN_DEALER && (
              <>
                <div className="">
                  <div className="textright mt-2">
                    <div
                      onClick={() => {
                        if (formValue.userName) {
                          sendOtp(formValue.userName, "forgot");
                        } else {
                          setErrorMessage(
                            "Please Enter User ID / Email ID / Mobile No."
                          );
                        }
                      }}
                      className="LoginRightRadioDivTitle "
                    >
                      Forgot Password?
                    </div>
                  </div>

                  <div className="textright pt-2 pb-5">
                    <div
                      onClick={() => {
                        if (formValue.userName) {
                          if (toggleOtp === false) {
                            sendOtp(formValue.userName);
                          }
                          setErrorMessage("");
                          let temp = formValue;
                          temp.password = "";
                          setFormValue(temp);
                          setToggleOtp(!toggleOtp);
                        } else {
                          setErrorMessage(
                            "Please Enter User ID / Email ID / Mobile No."
                          );
                        }
                      }}
                      className="LoginRightRadioDivTitle"
                    >
                      {toggleOtp ? "Login using password?" : "Login using OTP?"}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </Col>
      </Row>
    </div>
    </>
  );
};
export default compose(withRouter)(LoginPage);
